@import url(https://fonts.googleapis.com/css2?family=Lustria&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Italianno&display=swap);
@font-face {
  font-family: 'noto_sansbold';
  src: url(/static/media/notosans-bold-webfont.088e035e.woff2) format("woff2"),url(/static/media/notosans-bold-webfont.15acd799.woff) format("woff");
  font-weight: normal;
  font-style: 600;
}

@font-face {
  font-family: 'noto_sansbold_italic';
  src: url(/static/media/notosans-bolditalic-webfont.5a2d6f8b.woff2) format("woff2"),url(/static/media/notosans-bolditalic-webfont.b18c7d2b.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'noto_sansitalic';
  src: url(/static/media/notosans-italic-webfont.6bc7a801.woff2) format("woff2"),url(/static/media/notosans-italic-webfont.dac53c08.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'noto_sansregular';
  src: url(/static/media/NotoSerif.05f9a5b0.woff2) format("woff2"),url(/static/media/NotoSerif.04cbc631.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'noto_sansregular';
  src: url(/static/media/notosans-regular-webfont.b3c7cfa5.woff2) format("woff2"),url(/static/media/notosans-regular-webfont.e7ef3d34.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerif';
  src: url(/static/media/NotoSerif.05f9a5b0.woff2) format("woff2"),url(/static/media/NotoSerif.04cbc631.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@media (min-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1430px !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .px-xxl-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .col-xxl-5 {
    flex: 0 0 41.666667% !important;
    max-width: 41.666667% !important;
  }

  .order-xxl-3 {
    order: 3;
  }

  .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
}

.create-account-button-holder {
  background-image: url("/images/watermark.png");
  background-position-x: right;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

@media (min-width: 1440px) {
  .create-account-mobile .create-account-mobile-content .create-account-button-holder {
    background-size: 464px !important;
    height: 433px;
    margin-bottom: -2px;
  }

  .create-account-mobile .create-account-mobile-content .create-account-button-holder a {
    margin-top: 82px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 391px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .create-account-mobile .create-account-mobile-content .create-account-button-holder {
    background-size: 365px !important;
    height: 340px;
    margin-bottom: 16px;
  }

  .create-account-mobile .create-account-mobile-content .create-account-button-holder a {
    margin-top: 54px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 310px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .create-account-mobile .create-account-mobile-content .create-account-button-holder {
    background-size: 303px !important;
    height: 282px;
    margin-bottom: 26px;
  }

  .create-account-mobile .create-account-mobile-content .create-account-button-holder a {
    margin-top: 37px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 263px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .create-account-mobile .create-account-mobile-content .create-account-button-holder {
    background-size: 224px !important;
    height: 210px;
    margin-bottom: -65px;
  }

  .create-account-mobile .create-account-mobile-content .create-account-button-holder a {
    margin-top: 15px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 192px !important;
  }
}

@media (max-width: 767px) {
  .create-account-mobile .create-account-mobile-content .create-account-button-holder {
    background-size: 279px;
    height: 261px;
    margin-bottom: -44px;
    padding-top: 25px;
  }

  .create-account-mobile .create-account-mobile-content .create-account-button-holder a {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .create-account-mobile .create-account-mobile-content .create-account-button-holder {
    background-size: 270px;
    height: 285px;
    padding-top: 61px;
  }

  .create-account-mobile .create-account-mobile-content .create-account-button-holder a {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0c1014;
  font-family: 'noto_sansregular';
}

h1.highlight,
h2.highlight,
h3.highlight,
h4.highlight,
h5.highlight,
h6.highlight {
  color: #014a6a;
}

h3 {
  font-size: 28px;
}

b {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400 !important;
}

label {
  font-family: 'Noto Sans', sans-serif;
}

h4 {
  font-size: 16px !important;
}

small {
  font-size: 12px !important;
}

p {
  font-size: 14px !important;
}

p.muted {
  color: #93999c;
}

p,
small,
span {
  color: #515759;
  font-family: 'Noto Sans', sans-serif;
}

.box-item-content {
  display: flex;
  border-style: solid;
  border-radius: 3px;
  padding: 10px 20px;
  margin: 10px 20px;
  padding-right: 0;
}

.box-item-content .box-item-title {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-item-content .box-item-title p {
  font-size: 12px !important;
  font-weight: 600;
  margin: 0;
}

.box-item-content .box-item-title h4 {
  margin: 0;
  margin-bottom: 10px;
}

.box-item-content .box-content-subitems {
  display: flex;
  flex-wrap: wrap;
  width: 47%;
  height: 100%;
  flex: 1 1;
  margin-left: 26px;
}

.box-item-content .box-content-subitems span {
  width: 50%;
  font-size: 11px;
  line-height: 17px;
}

.box-item-content .coming-soon {
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  position: relative;
  left: -10px;
}

.btn-primary {
  background-color: #1890ff !important;
}

.navbar {
  box-shadow: 0px 15px 45px rgba(0,0,0,0.048);
}

.navbar .navbar-brand img {
  width: 200px;
}

.navbar-nav .nav-link {
  margin: 0px 30px !important;
  color: #000 !important;
  font-family: 'Noto Sans', sans-serif;
}

.navbar-nav .nav-link.active {
  color: #18a3de !important;
  text-decoration: underline;
}

.navbar-bottom-images {
  padding: 20px;
}

.navbar-bottom-images img {
  margin-right: 20px;
}

.navbar-bottom-images img.trace-for-better-img {
  position: relative;
  bottom: -5px;
}

.flex-1 {
  flex: 1 1 !important;
}

.showMobile {
  display: none !important;
}

.showTablet {
  display: none !important;
}

.verySmallShow {
  display: none;
}

.font-large {
  font-size: 16px !important;
}

.color-black {
  color: black;
}

.button-selector {
  display: flex;
  flex-direction: row;
}

.button-selector button {
  border: 1px solid #e4e8ef;
  background-color: #fff;
  width: 60px;
  height: 37px;
}

.button-selector button.selected {
  background-color: #0074a0;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar-light .navbar-nav .nav-link {
  color: violet;
  padding-right: 0 !important;
  padding-left: 0 !important;
  font-size: 14px !important;
  margin: 0 10px !important;
}

@media (min-width: 1440px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 15px !important;
    margin: 0px 30px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .navbar-light .navbar-nav .nav-link {
    margin: 0px 15px !important;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .hideTablet {
    display: none !important;
  }

  .showTablet {
    display: inherit !important;
  }

  .navbar-light .navbar-nav .nav-link {
    font-size: 12px !important;
  }

  .work-orders-services-bg {
    left: -93px;
    top: 76px;
  }

  .services-third-column {
    margin-left: -50px !important;
  }

  .services-third-column img {
    left: -94px !important;
    top: 80px !important;
  }

  .retailers-content {
    margin-bottom: 30px !important;
  }

  .retailers-content .newsletter-extra-fields {
    margin-top: -45px !important;
  }
}

@media (max-width: 1399px) {
  .hideTablet {
    display: none !important;
  }

  .showTablet {
    display: inherit !important;
  }
}

@media (max-width: 1399px) and (min-width: 992px) {
  .work-orders-services-bg {
    left: -93px;
    width: 338px !important;
    top: 76px;
  }

  .services-third-column {
    margin-left: -50px !important;
  }

  .services-third-column img {
    left: -94px !important;
    top: 80px !important;
  }

  .retailers-content {
    margin-bottom: 30px !important;
  }

  .retailers-content .newsletter-extra-fields {
    margin-top: -45px !important;
  }

  .retailers-page .newsletter-extra-fields {
    margin-top: -35px !important;
  }
}

@media (max-width: 1399px) {
  .retailers-content {
    margin-bottom: 80px;
  }

  .service-fee p {
    display: flex;
    flex-direction: column;
  }
}


button {
  font-family: 'noto_sansregular';
}

button:active {
  outline: none !important;
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.btn {
  border-radius: 25px !important;
  padding: 7px 35px !important;
}

.btn.btn-primary {
  background-color: #1daeec !important;
  border-color: #1daeec !important;
}

.btn.btn-sm {
  border-radius: 5px !important;
  padding: 5px 15px !important;
}

.btn.btn-sm.btn-secondary {
  background-color: #1e87f6 !important;
  border-color: #1e87f6 !important;
}

.input-group.with-button input {
  border-right-width: 0px;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.input-group.with-button input:focus {
  outline: none;
  box-shadow: none;
  border-color: #dad7d7;
}

.input-group.with-button button {
  padding: 0 !important;
  border-radius: 0px !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  background-color: #fff !important;
  border-left-width: 0px;
  border-color: #dad7d7;
}

.input-group.with-button button span {
  background-color: #82c1fc;
  color: #000;
  border-radius: 5px;
  padding: 1px 7px;
  margin-right: 5px;
  margin-left: 2px;
  border: 2px solid #b4bdc7;
  font-size: 12px;
}

.input-group.with-button.active button span {
  background-color: #8aca26;
  color: #ffda2d !important;
}

.btn-tab-blue {
  background: #f8f8f8;
  border: 2px solid #979797;
  border-radius: 4px;
  font-size: 14px;
  padding: 0.25rem 2rem;
}

.btn-tab-blue.active,
.btn-tab-blue:hover {
  background-color: #99c4fd;
  color: #ffffff;
}

.btn-wgt {
  border-radius: 5px;
  font-size: 14px;
  padding: 0.25rem 2rem;
}

.btn-wgt.primary {
  background: #8aca26;
  border: 1px solid rgba(55,60,62,0.2);
  color: #ffffff;
}

.btn-wgt.danger {
  background: #d31a1a;
  border: 1px solid rgba(55,60,62,0.2);
  color: #ffffff;
}

.btn-wgt.small {
  font-size: 12px;
  padding: 0.25rem 1rem;
}

.btn-wgt:disabled,
.btn-wgt:hover {
  opacity: 0.8;
}

.btn-wgt.dark-blue {
  background: #076993;
  border-radius: 24px;
  border: unset;
  color: #ffffff;
  font-size: 16px !important;
  padding: 0.5rem 3rem;
}

.btn-icon {
  background: transparent;
  border: unset;
  color: gray;
  font-size: 1.5rem;
}


@media (max-width: 991px) {
  body {
    overflow-x: hidden;
  }

  .navbar-brand {
    width: 50%;
  }

  .navbar-brand img {
    width: 50%;
  }

  .navbar-expand-lg .btn {
    margin-top: 20px;
    float: right;
    margin-bottom: 20px;
  }

  .footer {
    margin-top: 10px !important;
  }

  .index-page .footer {
    margin-top: -95px;
  }

  .index-page .index-component-item {
    margin: 20px 0 !important;
  }

  .service-fee {
    margin-bottom: 8px !important;
  }

  .service-fee p {
    margin: 10px 0 !important;
  }

  .work-orders-services-bg {
    top: 156px !important;
    width: 248px !important;
    left: -150px !important;
    height: 128px !important;
  }

  .box-item-content {
    margin: 0px 20px !important;
  }

  .showMobile {
    display: inherit !important;
  }

  .newsletter-holder .container {
    width: 100%;
    margin-left: 15px;
    padding-left: 0 !important;
  }

  .kimberley-holder {
    padding-top: 0 !important;
  }

  .hideMobile {
    display: none !important;
  }

  .m-mb-2 {
    margin-bottom: 1rem !important;
  }

  .m-mb-5 {
    margin-bottom: 3rem !important;
  }

  .newsletter-extra-fields {
    margin-left: 0px !important;
    border: none !important;
    padding: 8px;
    margin-bottom: -33px !important;
    margin-top: 86px !important;
  }

  .navbar-bottom-images {
    flex-direction: column;
  }

  .trace-for-better-world-text {
    font-size: 24px !important;
  }

  .popover {
    width: 300px !important;
    margin-left: 10px !important;
    left: 0px !important;
  }

  .popover .popover-body {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .services-third-column {
    margin-left: 0px !important;
  }

  .register-page .check-account-form {
    display: block !important;
  }

  .register-page .check-account-form button {
    margin-top: 1rem;
  }

  .register-page .firm-types-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  .register-page .firm-types-container .firm-types-dropdown-popover {
    display: block !important;
  }

  .register-page .register-personal-form .col-lg-7 {
    padding: 0px;
  }

  .register-page .register-personal-form .col-md-9 {
    padding: 0 !important;
  }

  .register-page .register-personal-form .col-md-10 {
    padding: 0 !important;
  }

  .register-page .register-personal-form .mr-2 {
    margin: 0 !important;
  }

  .register-page .register-personal-form .label-with-margin label {
    padding-right: 15px;
    padding-left: 15px;
  }

  .permission-form {
    display: block !important;
  }

  .permission-form.d-flex {
    display: block !important;
  }

  .noMobileMargins {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .noMobileMargins.mr-4 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .create-profile-form .form-group .col-md-9 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .create-profile-form .form-group .col-md-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .create-profile-form .form-group .col-md-3 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .noMobilePaddings {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .noMobilePaddings.px-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .retailers-content {
    margin-bottom: -45px !important;
  }

  .retailers-content .document-content {
    position: relative !important;
    left: 15px !important;
  }

  .retailers-content .document-content p {
    margin-left: 35px !important;
    padding-right: 2px;
  }

  .retailers-content {
    margin-bottom: -45px !important;
  }

  .retailers-content .m-mb-5 {
    padding-right: 4px;
  }

  .retailers-content .document-content {
    position: relative !important;
    left: -5px !important;
  }

  .retailers-content .document-content p {
    margin-left: 35px !important;
    padding-right: 2px;
  }

  .break-line-ipad {
    padding-right: 0px !important;
  }

  .newsletter-holder .container {
    padding-left: 0px !important;
    margin-left: 15px !important;
  }

  .newsletter-holder .container .py-4 {
    padding-right: 10px !important;
  }

  .service-fee p {
    display: block;
    flex-direction: row;
  }
}

@media (max-width: 991px) and (min-width: 767px) {
  .break-line-ipad {
    padding-right: 10px !important;
  }

  .service-fee p {
    display: block;
  }

  .newsletter-holder .container {
    padding-left: 19px !important;
    margin-left: 20px !important;
  }

  .newsletter-holder .container .py-4 {
    padding-right: 25px !important;
  }

  .retailers-content {
    margin-bottom: -45px !important;
  }

  .retailers-content .document-content {
    position: relative !important;
    left: 15px !important;
  }

  .retailers-content .document-content p {
    margin-left: 35px !important;
    padding-right: 2px;
  }

  .work-orders-services-bg {
    top: 143px !important;
    width: 209% !important;
    left: -150px !important;
    height: 221px !important;
  }
}

@media (max-width: 991px) {
  .showIpad {
    display: none;
  }
}

@media (max-width: 321px) {
  .verySmallShow {
    display: block;
  }

  .verySmallHide {
    display: none;
  }

  .work-orders-services-bg {
    display: none;
  }
}

@media (max-width: 541px) and (min-width: 539px) {
  .work-orders-services-bg {
    top: 100px !important;
    left: -100px !important;
  }
}

@media (max-width: 361px) and (min-width: 359px) {
  .work-orders-services-bg {
    top: 162px !important;
    left: -150px !important;
  }
}

@media (max-width: 769px) and (min-width: 767px) {
  .showIpad {
    display: block;
  }

  .hideIpad {
    display: none;
  }

  .work-orders-services-bg {
    height: 100px !important;
    width: 139% !important;
    left: -78px !important;
  }
}

@media (max-width: 1025px) and (min-width: 1023px) {
  .ipadProPadding {
    padding-right: 50px;
  }
}


